import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { useLoader, useFrame, useThree } from "@react-three/fiber";
import { Canvas } from "@react-three/fiber";
import { PLYLoader } from "three/addons/loaders/PLYLoader";
import ScrollDown from "./ScrollDown";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { AnimatedText } from "./AnimatedText";
import { motion } from 'framer-motion';

//initial state of spot light controls
const map = "disturb.jpg";
const intensity = 40;
const distance = 100;
const angle = Math.PI / 4;
const penumbra = 0.8;
const decay = 2;
const focus = 1;
const x = 97,
  y = 60,
  z = 34;

const color = "#157b8d";

const LucyModel = () => {
  const ply = useLoader(PLYLoader, "/models/Lucy100k.ply");
  const { camera } = useThree();

  useEffect(() => {
    camera.position.set(45, 54, 24);
  }, []);

  const meshRef = useRef();

  // Create mesh and apply properties
  useEffect(() => {
    console.log("here");

    ply.computeVertexNormals();
  }, []);

  // set the position of the model based on the values of the controls

  return (
    <mesh
      position={[-3, 18, 4]}
      rotation={[-0.12, -1.9, -0.1]}
      castShadow
      recieveShadow
      ref={meshRef}
      geometry={ply}
      scale={0.025}
    >
      <meshLambertMaterial />
    </mesh>
  );
};

export default function Main() {

  const words = ['You', 'already', 'know'];


  const spotLightRef = React.useRef();
  const lightHelperRef = React.useRef();

  const [textures, setTextures] = React.useState(null);

  React.useEffect(() => {
    const loader = new THREE.TextureLoader().setPath("/textures/");
    const filenames = ["disturb.jpg", "colors.webp", "uv_grid_opengl.webp"];
    const newTextures = { none: null };
    for (let i = 0; i < filenames.length; i++) {
      const filename = filenames[i];
      const texture = loader.load(filename, () => {
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.colorSpace = THREE.sRGBEncoding;
      });
      newTextures[filename] = texture;
    }
    setTextures(newTextures);
  }, []);

  //to rotate spot light continuously
  const RotateLight = () => {
    useFrame(() => {
      const time = performance.now() / 3000;

      spotLightRef.current.position.x = Math.cos(time) * 30;
      spotLightRef.current.position.z = Math.sin(time) * 30;
    });
    return (
      <spotLight
        ref={spotLightRef}
        color={color}
        position={[10, 50, 25]}
        intensity={intensity}
        distance={distance}
        map={textures["disturb.jpg"]}
        angle={angle}
        penumbra={penumbra}
        decay={decay}
        castShadow
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-camera-near={20}
        shadow-camera-far={200}
        shadow-focus={focus}
      />
    );
  };

  return (
    <div
      style={{
        height: "90vh",

        position: "relative",
      }}
    >
      <div
        style={{
          height: "100%",
        }}
        className="left-[-25%]"
      >
        {" "}
        <Canvas
          camera={{
            position: [70, 50, 10],
            fov: 40,
            near: 1,
            far: 1000,
          }}
          shadows
        >
          <hemisphereLight
            skyColor="#ffffff" // color of the sky
            groundColor="#8d8d8d" // color of the ground
            intensity={0.05} // intensity of the light intensity={0.35}
          />

          <mesh receiveShadow rotation-x={-Math.PI / 2} position={[0, 0, 0]}>
            <planeGeometry args={[1000, 1000]} />
            <meshLambertMaterial color={0x808080} />
          </mesh>

          <LucyModel />

          <RotateLight />

          {spotLightRef.current && (
            <spotLightHelper
              args={[spotLightRef.current]}
              ref={lightHelperRef}
            />
          )}
        </Canvas>
      </div>

      <div
        style={{
          position: "absolute",
          //top: "15%",
          // left : '15%',
          zIndex: 5,
        }}
        className="flex flex-col h-3/4 left-[2%] right-[2%] top-[15%] md:left-[15%] text-white"
      >
        <span
          style={{
            overflow: "hidden",
            minHeight: "40px",
            height: "auto",
          }}
        >
          <p
            className="font-[yeezy-tstar-700]"
            style={{
              fontSize: "25px",
              lineHeight: "64px",
              marginBottom: "0",
              fontWeight: "500",
            }}
          >
            {" "}
            <AnimatedText
              el="h2"
              text={[
                "You already know",
              ]}
              className=""
              repeatDelay={100000}
              delay={800}
              once={true}
            />
          </p>
        </span>
        <span
          style={{
            overflow: "hidden",
            minHeight: "72px",
            marginBottom: "2rem",
          }}
        >
          <p
            style={{
              lineHeight: "64px",
              marginBottom: "0.5rem",
              fontWeight: "500",
            }}
            className=" text-[60px] md:text-[56px] font-[yeezy-tstar-700] "
          >
            {/* starts after 1 seconds delay*/}
            <AnimatedText
              el="h2"
              text={[
                "It's Time to",
                "Get Ahead"
              ]}
              className=""
              repeatDelay={100000}
              delay={2550}
              once={true}
            />
          </p>
        </span>

        <span
          style={{
            minHeight: "50px",
          }}
        >
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 7.5 }}
            duration={1}

            style={{
              fontSize: 15,
              lineHeight: "24px",
              fontWeight: 500,
            }}
            className="  font-[yeezy-tstar-700]"
          >
            {" "}
            The Nordic Pioneers in AI Excellence,<br></br> <u>Now</u> is the
            Chance to Empower Your Business with Cutting-Edge AI Solutions{" "}
          </motion.p>
          <br></br>
        </span>
        {/* flex max-sm:mt-48 max-w-sm md:max-w-lg */}
        <div className="flex flex-col md:flex-row gap-5 md:gap-0 justify-center items-center md:justify-between max-w-[450px] mt-36 md:mt-5">
          <div>
            <AnchorLink href="#speakToTeam">
              <button className="h-auto mx-auto w-full z-10 relative py-4" id="b">
                <span className="text-[16px] font-[yeezy-tstar-700]">
                  Speak to our team
                </span>
                <br />
              </button>
            </AnchorLink>
          </div>
          <div>
            <AnchorLink href="#services">
              <button className="h-auto mx-auto w-full z-10 relative py-4" id="b">
                <span className="text-md font-[yeezy-tstar-700]">
                  Our services
                </span>
              </button>
            </AnchorLink>
          </div>
        </div>
      </div>
      <ScrollDown borderColor={"text-white"} />
    </div>
  );
}
