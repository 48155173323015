export const INPUT_DATA_1 = [
  {
    name: "first_name",
    type: "text",
    placeholder: "",
    required: true,
  },
];

export const INPUT_DATA = [
  {
    name: "email",
    type: "email",
    placeholder: "",
    required: true,
  },
  {
    name: "subject",
    type: "text",
    placeholder: "",
  },
];

export const TEXTAREA_DATA = [
  {
    name: "text",
    type: "text",
    placeholder: "",
    required: true,
  },
];

export const INPUT_SCHEMA = {
  first_name: "",
  last_name: "",
  email: "",
  subject: "",
  text: "",
};

export const CAROUSEL_DATA = [
  {
    main: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos molestias porro, asperiores architecto dolorum fuga provident illo ipsa distinctio labore?",
    img: "https://nortal.com/wp-content/uploads/2022/05/img-delivered-portrait.jpg",
    name: "Lorem ipsum dolor sit amet",
    logo: "ZODBYTE",
    tag2: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora, inventore.",
    tag3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora, inventore.Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora, inventore.",
    bgColor: "bg-slate-500",
  },
  {
    main: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos molestias porro, asperiores architecto dolorum fuga provident illo ipsa distinctio labore?",
    img: "https://nortal.com/wp-content/uploads/2022/05/img-delivered-portrait.jpg",
    name: "Lorem ipsum dolor sit amet",
    logo: "ZODBYTE",
    tag2: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora, inventore.",
    tag3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora, inventore.Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora, inventore.",
    bgColor: "bg-yellow-500",
  },
];
