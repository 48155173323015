import React from "react";
// import WaveSection from "./WaveSection";
import statue1 from "../assets/statuedarkquantized1.png";
import { AiFillInstagram, AiFillLinkedin } from "react-icons/ai";
import { FaTelegram } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Footer = () => {
  return (
    <>
      {/* <WaveSection/> */}
      <footer className="">
        <section
          className="md:block  w-full md:px-[8%] pt-[39px] pb-10 justify-center relative overflow-hidden
      b bg-[#5E6C8C] footer-body"
        >
          {/* Footer Color above */}
          <div className="flex flex-col md:flex-row w-full justify-center gap-4 text-[helvetica] text-[#22181C] text-[16px] font-[500]">
            <div className="flex-1 border rounded-lg  m-3 flex flex-col items-start text-[#22181C] p-3 with-neumorphism solution-item-copy bg-[#F5F5F4]">
              <div className="my-2">
                <p className="text-[#22181C]">Got any questions?</p>
                <p className="text-[#22181C]">
                  Feel free to email us at :{" "}
                  <span className="underline underline-offset-2">
                    info@zodbyte.com
                  </span>
                </p>
              </div>

              <ul className="list-none flex flex-col lg:flex-row text-[16px] font-medium lg:divide-x-2 lg:divide-black my-4">
                <li>
                  <AnchorLink
                    href="#services"
                    className="underline underline-offset-2 cursor-pointer lg:pr-3 "
                  >
                    {" "}
                    Our services{" "}
                  </AnchorLink>
                </li>
                <li>
                  <a
                    href="https://medium.com/@zodbyte/test-f6e3bce926a6"
                    target="_blank"
                    className="underline underline-offset-2 cursor-pointer lg:px-3"
                  >
                    {" "}
                    Results{" "}
                  </a>
                </li>
                <li>
                  <AnchorLink
                    href="#insights"
                    className="underline underline-offset-2 cursor-pointer lg:px-3"
                  >
                    {" "}
                    Insights{" "}
                  </AnchorLink>
                </li>
                <li>
                  <a
                    href="https://calendly.com/zodbyte-ai/15-minute-meeting-for-automating-your-business"
                    target="_blank"
                    className="underline underline-offset-2 cursor-pointer lg:px-3"
                  >
                    {" "}
                    Book A Meeting{" "}
                  </a>
                </li>
              </ul>

              <div className="flex space-x-4 my-4 ">
                <a
                  href="https://www.instagram.com/zodbyte/"
                  className="text-[45px]"
                >
                  <AiFillInstagram />
                </a>
                <a
                  href="https://www.linkedin.com/company/zodbyte"
                  className="text-[45px]"
                >
                  <AiFillLinkedin />
                </a>
                <a href="https://t.me/Zodbyte" className="text-[45px]">
                  <FaTelegram />
                </a>
                <a href="https://wa.link/1dl4gi" className="text-[45px]">
                  <IoLogoWhatsapp />
                </a>
              </div>
            </div>

            <div className="flex-1 border rounded-lg m-3 flex flex-col justify-between items-start text-[#22181C] p-3 with-neumorphism solution-item-copy bg-[#F5F5F4]">
              <p className="text-[#22181C] text-[16px]">
                We believe that the future of business is AI-driven, and
                adapting isn't optional - it's crucial for survival and growth.
              </p>
              <p className="text-[#22181C] text-[16px]">
                Whether you're taking your first steps into the AI realm or
                looking to expand your current AI capabilities, Zodbyte is here
                to make your journey seamless and rewarding.
              </p>
              <ul className="list-none flex text-[16px] font-medium divide-x-2 divide-black my-4">
                <li>
                  <a
                    href="#"
                    className="underline underline-offset-2 cursor-pointer pr-3 "
                  >
                    {" "}
                    Privacy Policy{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="underline underline-offset-2 cursor-pointer px-3"
                  >
                    {" "}
                    Terms of Service{" "}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <hr className="hidden md:block w-3/4 mx-auto border-2 border-white my-4 with-neumorphism" />
          <div className="w-full flex justify-center md:hidden mt-[10px]">
            <p
              className="text-[10px] mx-auto  text-[#dde1e7] mt-[-15px] font-[yeezy-tstar-700] "
              style={{ fontWeight: "500" }}
            >
              © 2023 Zodbyte. All rights reserved.
            </p>
          </div>
          <div className=" justify-center gap-4 relative z-10 mt-12 hidden md:flex">
            <img className="h-[178px] opacity-10" src={statue1} alt="" />
            <div className="text-center flex flex-col my-4 items-between justify-start">
              <h1 className="text-[58px] font-tesla text-[#dde1e7] ">
                Zodbyte
              </h1>
              <p
                className="text-[10px] text-[#dde1e7] mt-[-15px] font-[yeezy-tstar-700]"
                style={{ fontWeight: "500" }}
              >
                © 2023 Zodbyte. All rights reserved.
              </p>
            </div>
            <img
              className="h-[178px] transform -scale-x-100 opacity-10"
              src={statue1}
              alt=""
            />
          </div>
        </section>
        <section className=" hidden px-[8%] py-10 w-full relative overflow-hidden ">
          {/* <StripeGradient/> */}
          <h6 className="font-[yeezy-tstar-700] text-[32px] leading-[39px] ml-10 z-10">
            @zodbyte2k22
          </h6>
          <div className="flex-1 bg-[#D9D9D9] p-10 pb-20 mt-6 z-10">
            <ul className="list-inside list-disc font-[yeezy-tstar-700] min-h-[300px] text-[32px] leading-[39px]">
              <li>zodbyte 2k22</li>
              <li>zodbyte 2k22</li>
              <li>zodbyte 2k22</li>
            </ul>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
