import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Main from "../components/Main";
import Globe from "../components/GlobeSection";
import AnimatedPicComponent from "../components/AnimatedPicComponent";
import { useStateContext } from "../context/context_provider";
import {
  INPUT_DATA,
  INPUT_DATA_1,
  INPUT_SCHEMA,
  TEXTAREA_DATA,
} from "../Data/InputData";
import ScrollDown from "../components/ScrollDown";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material";
import { MenuItem } from "@mui/material";
import { FadeInDiv } from "../components/FadeInDiv";
const CssTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    color: "#FFF", // Change the text color to white
  },
  "& .MuiInputLabel-root": {
    color: "#FFF", // Change the label color to white
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#FFF", // Change the underline color to white
  },
  "& .MuiInputLabel-shrink": {
    color: "#FFF", // Change the floated label color to white
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FFF", // Change the outline color to white
    },
    "&:hover fieldset": {
      borderColor: "#FFF", // Change the outline color on hover to white
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFF", // Change the outline color when focused to white
    },
  },
}));

const CssTextFieldBlack = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    color: "#06B9D4", // Change the text color to white
  },
  "& .MuiInputLabel-root": {
    color: "#06B9D4", // Change the label color to white
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "#06B9D4", // Change the underline color to white
  },
  "& .MuiInputLabel-shrink": {
    color: "#06B9D4", // Change the floated label color to white
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#06B9D4", // Change the outline color to white
    },
    "&:hover fieldset": {
      borderColor: "#06B9D4", // Change the outline color on hover to white
    },
    "&.Mui-focused fieldset": {
      borderColor: "#06B9D4", // Change the outline color when focused to white
    },
  },
}));

const Home = () => {
  const { language } = useStateContext();
  const [values, setValues] = useState(INPUT_SCHEMA);
  const form = useRef();
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const {
    body: { section3, section4 },
  } = language?.content;

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_t2yo2yu", // service key
        "template_2la2kqv", // template key
        form.current,
        "user_4qjomoYaesK6lyCVrjXKF" // public key
      )
      .then(
        (result) => {
          alert("Email sent!");
          setValues(INPUT_SCHEMA);
        },
        (error) => {
          alert("Email sent failed");
          console.log(error);
        }
      );
  };

  return (
    <main className="bg-[#F5F5F4] pt-[80px] w-full overflow-x-hidden ">
      {/* Section 01 */}
      <Main />

      <Globe />

      <div className="who-is-for-container hidden md:block">
        <div class="who-is-for-heading">
          <h1 className="font-[yeezy-tstar-700] text-[34px]">
            So who is this for?
          </h1>
          <p>
            Here are just a few examples of use cases for our custom AI chatbots
          </p>
        </div>
        <div class="max-w-7xl mx-auto mt-10 px-5 xl:px-0">
          <FadeInDiv
            className="your-custom-div-class"
            animation={{
              hidden: { opacity: 0, y: 30 },
              visible: { opacity: 1, y: 0, transition: { duration: 1 } },
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7">
              {[
                {
                  title: "🥗 Restaurants",
                  text: "Handles reservations, answers menu queries, and collects customer feedback.",
                },
                {
                  title: "👔️ Retail",
                  text: "Provides personalized recommendations, assists with product queries and checkouts.",
                },
                {
                  title: "💪️ Fitness Studios",
                  text: "Schedules classes, offers workout tips, and answers membership queries.",
                },
                {
                  title: "⚖️ Legal Services",
                  text: "Answers legal queries, books consultations, and updates on case progress.",
                },
                {
                  title: "🏠 Real Estate",
                  text: "Details property listings, schedules viewings, and answers related questions.",
                },
                {
                  title: "🩺 Healthcare Practices",
                  text: "Schedules appointments, offers health advice, and sends medication reminders.",
                },
                {
                  title: "🦷 Dental Clinics",
                  text: "Assists with booking appointments, provides pre/post-procedure instructions.",
                },
                {
                  title: "💼 Accounting Firms",
                  text: "Answers tax queries, schedules accountant meetings, and sends tax-filing reminders.",
                },
                {
                  title: "🌍 Tourism Agencies",
                  text: "Provides travel package info, assists with bookings, offers travel advice.",
                },
                {
                  title: "️📆 Event Management",
                  text: "Assists with registration, offers event details, and collects attendee feedback.",
                },
                {
                  title: "📈 Marketing Agencies",
                  text: "Shares service details, generates leads, and books consultations.",
                },
                {
                  title: "💻 IT Services",
                  text: "Offers troubleshooting guides, schedules service calls, and updates on resolution.",
                },
                {
                  title: "💵 Financial Advisory",
                  text: "Answers finance questions, schedules advisor meetings, provides market updates.",
                },
                {
                  title: "🎓 Education Institutions",
                  text: "Provides course information, assists with admissions, and answers student queries.",
                },
                {
                  title: "🚗 Automotive Services",
                  text: "Books service appointments, provides vehicle maintenance tips.",
                },
                {
                  title: "️💜 Non-Profit",
                  text: "Shares mission statements, facilitates donations, updates on projects.",
                },
                {
                  title: "💇 Hair Salons",
                  text: "Books appointments, offers hair care tips, provides service/pricing details.",
                },
                {
                  title: "🐾 Pet Care Services",
                  text: "Schedules appointments, offers pet care tips.",
                },
                {
                  title: "🛋️ Interior Design",
                  text: "Showcases portfolio, schedules consultations, offers décor tips.",
                },
                {
                  title: "📸 Photography Services",
                  text: "Shares portfolio, schedules photo shoots, provides package details.",
                },
                {
                  title: "👉 Your Business",
                  text: "Every business can benefit from Custom AI Zodbots.",
                },
              ].map((item, index) => (
                <a
                  key={index}
                  href="https://calendly.com/zodbyte-ai/15-minute-meeting-for-automating-your-business"
                  target="_blank"
                  className="who-is-for-item solution-item-copy with-neumorphism"
                >
                  <h3>{item.title}</h3>
                  <p>{item.text}</p>
                </a>
              ))}
            </div>
          </FadeInDiv>

        </div>
      </div>

      {/* Section 02 & 03 */}
      <div id="insights" className=" md:flex-col flex-col-reverse hidden md:flex ">
        {/* In mobile version section 2 & 3 will be swaped */}

        {/* Section 03, Mob section 02 */}
        <section className="md:p-[5%] md:pt-[5%] max-md:py-[8%] w-full max-w-[1600px] mx-auto">
          <div id="service" className="md:h-[25px]"></div>
          <h2 className="insights text-black font-[yeezy-tstar-700]  text-center text-[40px]  leading-[39px] sm:leading-[34px] mx-auto  md:mb-[60px] pb-3">
            Our latest insights
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 justify-center gap-5">
            <AnimatedPicComponent
              title={section3.tag1.title}
              des={section3.tag1.des}
              btnText={section3.tag1.buttonText}
              url={"https://medium.com/@zodbyte/test-f6e3bce926a6"}
              src="https://i.imgur.com/JdkxdjH.jpeg"
            />
            <div className="w-full border-t-[1px]  my-2 md:hidden block"></div>
            <AnimatedPicComponent
              title={section3.tag2.title}
              des={section3.tag2.des}
              btnText={section3.tag2.buttonText}
              url={"https://medium.com/@zodbyte/test-f6e3bce926a6"}
              src={"https://i.imgur.com/s7hkXq6.png"}
            />
            <div className="w-full border-t-[1px]  my-2 md:hidden block"></div>
            <AnimatedPicComponent
              title={section3.tag3.title}
              des={section3.tag3.des}
              btnText={section3.tag3.buttonText}
              url={"https://medium.com/@zodbyte/test-f6e3bce926a6"}
              src={`https://nortal.com/wp-content/uploads/2022/05/img-delivered-portrait.jpg`}
            />
          </div>
        </section>
      </div>

      <div id="speakToTeam" className="w-full relative my-4 text-center pt-10">
        <h2 className="relative  font-[yeezy-tstar-700] md:text-[40px] max-md:text-[30px] md:leading-[36px] leading-[39px] max-md:text-center w-full text-gradient pb-3">
          Time to Leverage AI for Your Business
        </h2>
        <div className="h-[2px] md:w-1/2 w-3/4 mx-auto bg-gradient-to-r from-[#0c91f3] to-[#00e3b5] my-8 mb-[80px] with-neumorphism">
          <p className="text-transparent"></p>
        </div>

        <div className="relative top-[20px] text-[#04C5CB] with-neumorphism">
          <ScrollDown />
        </div>
        <h2
          style={{ padding: "15px" }}
          className="text-gradient font-[yeezy-tstar-700] md:text-[24px] max-md:text-[20px] mt-[100px]"
        >
          LET'S GET IN TOUCH
        </h2>
        <div>
          <form
            ref={form}
            onSubmit={sendEmail}
            className="bg-[#FEFEF] md:w-[500px] w-[90%] my-0 mx-auto"
          // style={{ width: "500px", margin: "0px auto" }}
          >
            {INPUT_DATA_1.map((input, index) => (
              <CssTextFieldBlack
                id="outlined-basic"
                label="Full name"
                variant="standard"
                key={index}
                {...input}
                value={values[input.name]}
                onChange={onChange}
                color="primary"
                style={{ width: "100%" }}
              />
            ))}
            <div className="flex my-5 flex-col md:flex-row flex-wrap gap-3 lg:gap-5">
              {INPUT_DATA.map((input, index) => (
                <CssTextFieldBlack
                  id="outlined-basic"
                  label={section4.subSec2.form[input.name]}
                  variant="standard"
                  key={index}
                  {...input}
                  placeholder={input.placeholder}
                  value={values[input.name]}
                  onChange={onChange}
                  className="md:w-[48%] w-full md:px-4  my-4 bg-transparent"
                />
              ))}
            </div>
            {TEXTAREA_DATA.map((input, index) => (
              <CssTextFieldBlack
                id="outlined-multiline-static"
                label="Tell us about your project"
                multiline
                rows={4}
                defaultValue=""
                key={index}
                {...input}
                value={values[input.name]}
                onChange={onChange}
                className="w-[100%] bg-transparent"
              />
            ))}
            <div
              style={{
                paddingTop: "20px",
                paddingBottom: "20px",
                textAlign: "start",
              }}
            >
              <label className="text-[#06B9D4] text-[16px]">
                What's your budget for this project?
              </label>
              <CssTextFieldBlack
                id="outlined-basic"
                variant="standard"
                select
                color="primary"
                style={{ width: "100%" }}
                defaultValue={"less"}
              >
                <MenuItem key={"less"} value={"less"}>
                  less than $5,000
                </MenuItem>
                <MenuItem key={"more"} value={"more"}>
                  $5,000 - $10,000
                </MenuItem>
                <MenuItem key={"more"} value={"more"}>
                  $10,000 - $20,000
                </MenuItem>
                <MenuItem key={"more"} value={"more"}>
                  $20,000 - $40,000
                </MenuItem>
                <MenuItem key={"more"} value={"more"}>
                  $40,000+
                </MenuItem>
              </CssTextFieldBlack>
            </div>
            <div
              style={{ paddingBottom: "20px", paddingTop: "20px" }}
              className=" w-full"
            >
              <div className="text-center flex justify-center">
                <button className="text-gradient submit-btn font-[helvetica] mx-auto left-auto md:left-0 right-0 relative my-4 with-neumorphism">
                  <span>Send inquiry</span>
                  <img
                    className="submit-img"
                    src="https://i.cloudup.com/2ZAX3hVsBE-3000x3000.png"
                    borderColor="black"
                    height="62px"
                    width="62px"
                    alt=""
                  />
                </button>
              </div>
            </div>
          </form>

          <p className="text-gradient text-xl font-medium text-[#868686] my-2">
            - Or simply -
          </p>
          <div className="w-[90%] mx-auto md:w-full">
            <button className="text-gradient book-btn font-[helvetica] text-[20px] mx-auto left-auto md:left-0 right-0 my-4 mb-14 with-neumorphism">
              <a
                href="https://calendly.com/zodbyte-ai/15-minute-meeting-for-automating-your-business"
                target="_blank"
              >
                <span>Book A Meeting</span>
              </a>
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Home;
