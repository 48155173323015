import { motion, useInView, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";

const defaultParagraphAnimations = {
    hidden: {
        opacity: 0,
        y: 20, // You can adjust the starting position as needed
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.4,
        },
    },
};

export const DelayedParagraph = ({
    text,
    el: Wrapper = "p",
    className,
    delay,
    animation = defaultParagraphAnimations,
}) => {
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { triggerOnce: true });

    useEffect(() => {
        let timeout;

        const show = () => {
            // Show after the specified delay
            timeout = setTimeout(() => {
                controls.start("visible");
            }, delay);
        };

        if (isInView) {
            show();
        } else {
            controls.start("hidden");
        }

        return () => clearTimeout(timeout);
    }, [isInView, delay]);

    return (
        <Wrapper className={className}>
            <motion.span
                ref={ref}
                initial="hidden"
                animate={controls}
                variants={animation}
                aria-hidden
            >
                {text}
            </motion.span>
        </Wrapper>
    );
};
