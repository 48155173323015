import React, { useState } from "react";
import Logo from "../../assets/header logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { animated } from "react-spring";
import { HashLink } from "react-router-hash-link";
import { Divider } from "@mui/material";

const PrimaryAppbar = (props) => {
  const [visibility, setVisibility] = useState(false);

  return (
    <animated.header
      {...props}
      className="fixed w-full z-[9999999] bg-white border-b-2 border-white"
    >
      <nav className="flex md:items-center  lg:max-w-full items-start md:flex-row w-full shadow-md  lg:px-[24px]  h-[100%] bg-white">
        {/* brand */}
        <HashLink
          smooth
          to={"/#"}
          className="flex  lg:ms-24 w-1/2 lg:w-1/4 h-full items-center relative cursor-pointer"
        >
          <img
            className="mb-1"
            src={Logo}
            alt="Logo"
            width={"auto"}
            height={""}
          />
        </HashLink>

        {/* menu desktop*/}
        <div className="lg:flex flex-2 items-center justify-end w-3/4 border-1 gap-6 hidden">
          {/* <HashLink
            smooth
            to={"/#insights"}
            className={"font-[yeezy-tstar-700] text-[24px] leading-[39px]"}
          >
            About
          </HashLink> */}
          <AnchorLink
            href="#services"
            className={
              "font-[yeezy-tstar-700] text-[20px] flex items-center  leading-[39px]"
            }
          >
            {"Our services"}
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M6 9l6 6 6-6"/></svg> */}
          </AnchorLink>

          <Divider
            orientation="vertical"
            variant="middle"
            style={{
              height: 20,
              borderColor: "black",
              borderWidth: "1px",
              backgroundColor: "black",
            }}
          />

          <a
            href="https://medium.com/@zodbyte/test-f6e3bce926a6"
            target="_blank"
            className={"font-[yeezy-tstar-700] text-[20px] leading-[39px]"}
          >
            {"Results"}
          </a>

          <Divider
            orientation="vertical"
            variant="middle"
            style={{
              height: 20,
              borderColor: "black",
              borderWidth: "1px",
              backgroundColor: "black",
            }}
          />
          <AnchorLink
            href="#insights"
            className={"font-[yeezy-tstar-700] text-[20px] leading-[39px]"}
          >
            {"Insights"}
          </AnchorLink>

          <div className="brd-btn flex justify-center items-center with-neumorphism">
            <form>
              <a
                href="https://calendly.com/zodbyte-ai/15-minute-meeting-for-automating-your-business"
                target="_blank"
                className=" font-[yeezy-tstar-700] text-[20px] text-gradient-2 "
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Free Consultation
              </a>
            </form>
          </div>
        </div>
      </nav>

      {/* Mobile humburger icon */}
      <div className="lg:hidden flex items-center border-[1px] rounded-md border-black p-2 gap-5 w-[56px] h-[56px] absolute top-3 right-5 with-neumorphism">
        <GiHamburgerMenu
          onClick={() => setVisibility(!visibility)}
          fontSize={40}
        />
      </div>

      {/* menu mobile */}
      <nav
        className={`${visibility ? "flex" : "hidden"
          } lg:hidden flex-col justify-end w-full absolute top-[5.5rem] bg-white z-10 border-[1px] gap-3 p-[24px] top-16 mt-4`}
      >
        <AnchorLink
          href="#services"
          className={
            "font-[yeezy-tstar-700] text-[20px] leading-[24px] flex justify-center items-center"
          }
          onClick={() => setVisibility(false)}
        >
          Our Services
        </AnchorLink>
        <a
          href="https://medium.com/@zodbyte/test-f6e3bce926a6"
          target="_blank"
          className={
            "font-[yeezy-tstar-700] text-[20px] leading-[24px] flex justify-center items-center"
          }
          onClick={() => setVisibility(false)}
        >
          Results
        </a>
        <AnchorLink
          href="#insights"
          className={
            "font-[yeezy-tstar-700] text-[20px] leading-[24px] flex justify-center items-center"
          }
          onClick={() => setVisibility(false)}
        >
          Insights
        </AnchorLink>

        <HashLink
          smooth
          to={"/#contact-us"}
          className={
            "font-[yeezy-tstar-700] text-[20px] leading-[24px] flex justify-center items-center "
          }
          onClick={() => setVisibility(false)}
        >
          <div className="brd-btn flex justify-center items-center with-neumorphism">
            <form>
              <a
                href="https://calendly.com/zodbyte-ai/15-minute-meeting-for-automating-your-business"
                target="_blank"
                className=" font-[yeezy-tstar-700] text-[20px] text-gradient-2 "
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Free Consultation
              </a>
            </form>
          </div>
        </HashLink>
      </nav>
    </animated.header>
  );
};

export default PrimaryAppbar;
