const THREE = window.THREE;

var DAT = DAT || {};

console.log("Here");
DAT.Globe = function (container, opts) {
  opts = opts || {};

  var colorFn =
    opts.colorFn ||
    function (x) {
      var c = new THREE.Color();
      c.setHSL(0.441 + x / 2, 0.6, 0.75);
      return c;
    };

  var Shaders = {
    earth: {
      uniforms: {
        texture: { type: "t", value: null },
      },
      vertexShader: [
        "varying vec3 vNormal;",
        "varying vec2 vUv;",
        "void main() {",
        "gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.05 );",
        "vNormal = normalize( normalMatrix * normal );",
        "vUv = uv;",
        "}",
      ].join("\n"),
      fragmentShader: [
        "uniform sampler2D texture;",
        "varying vec3 vNormal;",
        "varying vec2 vUv;",
        "void main() {",
        "vec3 diffuse = texture2D( texture, vUv ).xyz;",
        "float intensity = 1.05 - dot( vNormal, vec3( 0.0, 0.0, 1.0 ) );",
        "vec3 atmosphere = vec3( 0, 1.0, 1.0 ) * pow( intensity, 3.0 );",
        "gl_FragColor = vec4( diffuse + atmosphere, 0.3 );",
        "}",
      ].join("\n"),
    },
    atmosphere: {
      uniforms: {},
      vertexShader: [
        "varying vec3 vNormal;",
        "void main() {",
        "vNormal = normalize( normalMatrix * normal );",
        "gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 0 );",
        "}",
      ].join("\n"),
      fragmentShader: [
        "varying vec3 vNormal;",
        "void main() {",
        "float intensity = pow( 0.8 - dot( vNormal, vec3( 0, 0, 1.0 ) ), 12.0 );",
        "gl_FragColor = vec4( 1.0, 1.0, 1.0, 1.0 ) * intensity;",
        "}",
      ].join("\n"),
    },
  };

  var camera, scene, renderer, w, h;
  var mesh, atmosphere, point;

  var overRenderer;

  var rotation = { x: 0, y: 0 };

  function init() {
    var shader, uniforms, material;
    w = container.offsetWidth || window.innerWidth;
    h = container.offsetHeight || window.innerHeight;

    camera = new THREE.PerspectiveCamera(30, w / h, 1, 10000);
    camera.position.z = 2000;

    scene = new THREE.Scene();

    var geometry = new THREE.SphereGeometry(200, 40, 30);

    shader = Shaders["earth"];
    uniforms = THREE.UniformsUtils.clone(shader.uniforms);

    THREE.ImageUtils.crossOrigin = "";

    uniforms["texture"].value = THREE.ImageUtils.loadTexture(
      "https://cdn.rawgit.com/dataarts/webgl-globe/2d24ba30/globe/world.jpg"
    );

    material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: shader.vertexShader,
      fragmentShader: shader.fragmentShader,
      transparent: true,
    });

    mesh = new THREE.Mesh(geometry, material);
    // mesh.rotation.y = Math.PI;
    //mesh.position.set(50, 0, 0);
    scene.add(mesh);

    shader = Shaders["atmosphere"];
    uniforms = THREE.UniformsUtils.clone(shader.uniforms);

    material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: shader.vertexShader,
      fragmentShader: shader.fragmentShader,
      side: THREE.BackSide,
      blending: THREE.AdditiveBlending,
      transparent: true,
    });

    mesh = new THREE.Mesh(geometry, material);
    mesh.scale.set(1.1, 1.1, 1.1);

    scene.add(mesh);

    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(w, h);

    renderer.domElement.style.position = "absolute";

    container.appendChild(renderer.domElement);

    container.addEventListener(
      "mouseover",
      function () {
        overRenderer = true;
      },
      false
    );

    container.addEventListener(
      "mouseout",
      function () {
        overRenderer = false;
      },
      false
    );

    window.addEventListener("resize", onWindowResize, false);
  }

  function addData(data, opts) {
    var lat, lng, size, color, i, step, colorFnWrapper;

    step = 3;
    colorFnWrapper = function (data, i) {
      return colorFn(data[i + 2]);
    };

    var subgeo = new THREE.Geometry();

    for (i = 0; i < data.length; i += step) {
      lat = data[i];
      lng = data[i + 1];
      color = colorFnWrapper(data, i);
      size = data[i + 2];
      size = size * 150;
      addPoint(lat, lng, size, color, subgeo);
    }

    this._baseGeometry = subgeo;
  }

  function createPoints() {
    this.points = new THREE.Mesh(
      this._baseGeometry,
      new THREE.MeshBasicMaterial({
        vertexColors: THREE.FaceColors,
        transparent: true,
      })
    );

    scene.add(this.points);
    //scene.position.set(80, 0, 0);
  }

  function addPoint(lat, lng, size, color, subgeo) {
    var phi = ((90 - lat) * Math.PI) / 180;
    var theta = ((180 - lng) * Math.PI) / 180;

    var point = new THREE.Mesh(
      new THREE.BoxGeometry(0.4, 0.5, 1),
      new THREE.MeshBasicMaterial({
        color: color,
      })
    );

    point.position.x = 200 * Math.sin(phi) * Math.cos(theta);
    point.position.y = 200 * Math.cos(phi);
    point.position.z = 200 * Math.sin(phi) * Math.sin(theta);

    point.lookAt(mesh.position);

    point.scale.z = Math.max(size, 0.1); // avoid non-invertible matrix

    point.updateMatrix();

    subgeo.merge(point.geometry, point.matrix);
  }

  function onWindowResize(event) {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }

  function animate() {
    requestAnimationFrame(animate);
    render();
  }

  function render() {
    rotation.x += 0.0005;

    camera.position.x = 2000 * Math.sin(rotation.x) * Math.cos(rotation.y);
    camera.position.y = 2000 * Math.sin(rotation.y);
    camera.position.z = 2000 * Math.cos(rotation.x) * Math.cos(rotation.y);
    camera.lookAt(scene.position);
    renderer.render(scene, camera);
  }

  init();
  this.animate = animate;

  this.addData = addData;
  this.createPoints = createPoints;
  this.renderer = renderer;
  this.scene = scene;

  return this;
};

export default DAT;
