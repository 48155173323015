import React from "react";
import "../styles/component.css";
import { BiChevronRight } from "react-icons/bi";

const AnimatedPicComponent = ({ src, title, des, url, btnText }) => {
  return (
    <a href={url} target="_blank">
      <div className="AnimatedPicContainer cursor-pointer relative md:min-h-[400px] max-md:h-[250px] md:mt-0 mt-5 flex flex-1 justify-center flex-col max-md:m-5">
        <div className="c-card-offset__image-placeholder"></div>
        <div className="AnimatedPicComponent bg-[#fff] w-[80%] h-auto z-30 self-center shadow-md p-[5%] max-md:py-10 relative max-md:absolute max-md:bottom-[10%] bottom-8 min-h-[300px]">
          <h2 className="font-[yeezy-tstar-700] lg:text-[20px] text-[20px] leading-[25px] mb-2">
            {title}
          </h2>
          <p className="color-black  text-[1vw] md:text-[18px] max-md:leading-3 text-black  lg:leading-[1.5vw] xl:leading-[1.3vw] mt-5">
            {des}
          </p>
          <button className="flex items-center relative font-[yeezy-tstar-700] h-6 text-[18px] leading-5 max-md:leading-[18px] mt-5 hover:border-b-2 hover:delay-75 border-black">
            {btnText}
            <BiChevronRight
              fontSize={30}
              className="absolute -right-6 top-[-1px]"
            />
          </button>
        </div>
        <picture className="c-card-offset__image ">
          <source srcSet={src} />
          <img
            className="c-image c-image--cover lazyloaded max-h-[520px]"
            loading="lazy"
            src={src}
            alt="The Future"
            data-ll-status="loaded"
          />
        </picture>
      </div>
    </a>
  );
};

export default AnimatedPicComponent;
