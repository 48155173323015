import React, { useRef, useEffect } from "react";
import { Canvas, useFra } from "@react-three/fiber";
import { Sphere, Html } from "@react-three/drei";
import * as THREE from "three";
import DAT from "./globe.js";
import leftBlur from "./left-blur.png";
import { DelayedParagraph } from "./DelayedParagraph";
import { FadeInDiv } from "./FadeInDiv.js";

export default function GlobeSection() {
  useEffect(() => {
    var container = document.getElementById("container");
    if (container.childNodes.length > 0) return;
    var globe = new DAT.Globe(container);

    var xhr = new XMLHttpRequest();

    xhr.open(
      "GET",
      "https://cdn.rawgit.com/dataarts/webgl-globe/2d24ba30/globe/population909500.json",
      true
    );

    xhr.onreadystatechange = function (e) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          var data = JSON.parse(xhr.responseText);
          window.data = data;
          for (let i = 0; i < data.length; i++) {
            globe.addData(data[i][1], {
              format: "magnitude",
              name: data[i][0],
              animated: false,
            });
          }
          globe.createPoints();
          globe.animate();
        }
      }
    };
    xhr.send(null);
  }, []);

  return (
    <div id="globeSectionDiv">
      <div
        style={{
          zIndex: 100,
          position: "relative",
          top: "140px",
        }}
        className="hidden md:block"
      >
        <h1
          style={{
            textTransform: "uppercase",
            textAlign: "center",
          }}
          className="text-gradient font-[yeezy-tstar-700] text-[34px]"
          id="phew"
        >
          The world is changing
        </h1>
      </div>

      <div
        style={{
          zIndex: 600,
          position: "relative",
          top: "345px"
        }}
        className="hidden md:block"
      >
        <div id="phew">
          <p id="L" className="pt-20">
            <DelayedParagraph
              text="The world as we know it is in the midst of an extraordinary transformation and AI is steering this incredible revolution."

              delay={500}
              animation={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0, transition: { duration: 0.4 } },
              }}
            />
            <br />

            <b>
              <DelayedParagraph
                text="Are you ready to ride the wave, or are you letting your business
              get swept away in the tide of change?"

                delay={1750}
                animation={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0, transition: { duration: 0.4 } },
                }}
              />
            </b>
            <br />

            <b>
              <u>
                <DelayedParagraph
                  text="Adapting to AI isn’t optional."

                  delay={3000}
                  animation={{
                    hidden: { opacity: 0, y: 20 },
                    visible: { opacity: 1, y: 0, transition: { duration: 0.4 } },
                  }}
                />
              </u>
            </b>
            <br />

            <DelayedParagraph
              text="Your competition right now is leveraging AI to reduce cost, increase
              output, and attract more customers."

              delay={4250}
              animation={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0, transition: { duration: 0.4 } },
              }}
            />
            <br />

            <DelayedParagraph
              text="If you don’t adapt, you risk being left behind."

              delay={5500}
              animation={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0, transition: { duration: 0.4 } },
              }}
            />
          </p>
        </div>
      </div>

      <div
        style={{
          zIndex: 400,
          height: "80vh",
          width: "100vw",
          //background: "black",
        }}
        className="hidden md:block"
      >
        <div
          className="relative "
          id="container"
          style={{
            left: "20%",
            bottom: "40px",
          }}
        ></div>
      </div>

      <di id="services">
        <div
          className=" space-y-10 py-10"
        ></div>
      </di>

      <div
        style={{
          width: "100%",
          // backgroundColor: 'black',
          zIndex: 800,
          position: "relative",
        }}
      >
        <h1
          style={{
            textTransform: "uppercase",
            textAlign: "center",
          }}
          className="text-gradient font-[yeezy-tstar-700] text-[34px] hidden md:block"
        >
          Our Solutions For You
        </h1>

        <FadeInDiv
          className="your-custom-div-class"
          animation={{
            hidden: { opacity: 0, y: 30 },
            visible: { opacity: 1, y: 0, transition: { duration: 1 } },
          }}
        >
          <div className="solution-container flex md:flex-row flex-col max-w-7xl mx-auto">
            <div className="solution-item md:min-h-[350px] lg:min-h-[250px] w-full md:w-1/3 text-gradient-2">
              <a
                href="https://calendly.com/zodbyte-ai/15-minute-meeting-for-automating-your-business"
                target="_blank"
              >
                <h3>Custom AI Chatbots</h3>
                <br />
                <p>Customer Service Chatbots</p>
                <p>Sales Assistance Chatbots</p>
                <p>Customer Acquisition Automation</p>
                <p>Employee Training Automation</p>
              </a>
            </div>
            <div className="solution-item md:min-h-[350px] lg:min-h-[250px] w-full md:w-1/3 text-gradient-2">
              <a
                href="https://calendly.com/zodbyte-ai/15-minute-meeting-for-automating-your-business"
                target="_blank"
              >
                <h3>AI Consulting</h3>
                <br />
                <p>Feasibility Assessments</p>
                <p>Use Case Identification</p>
                <p>Performance Evaluation</p>
                <p>Strategy Development</p>
              </a>
            </div>
            <div className="solution-item md:min-h-[350px] lg:min-h-[250px] w-full md:w-1/3 text-gradient-2">
              <a
                href="https://calendly.com/zodbyte-ai/15-minute-meeting-for-automating-your-business"
                target="_blank"
              >
                <h3>Custom Developments</h3>
                <br />
                <p>Website Development</p>
                <p>Lead Generation Systems</p>
                <p>Personalized Marketing</p>
                <p>Custom AI plugins</p>
              </a>
            </div>
          </div>
        </FadeInDiv>


      </div>
    </div>
  );
}
