import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

const defaultFadeInAnimations = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
        },
    },
};

export const FadeInDiv = ({
    children,
    className,
    animation = defaultFadeInAnimations,
}) => {
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { triggerOnce: true });

    useEffect(() => {
        if (isInView) {
            controls.start("visible");
        } else {
            controls.start("hidden");
        }
    }, [isInView]);

    return (
        <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={animation}
            className={className}
        >
            {children}
        </motion.div>
    );
};
