const LANGUAGE = [
  {
    lang: "English",
    content: {
      appbar: {
        itemOne: "Service",
        itemTwo: "Contact Us",
        langTag: "en",
      },
      body: {
        section1: {
          para1: "MODERN DAY MAGIC",
          para2:
            "We Help E-commerce & Info Product Businesses Produce Spine-Chilling ROI Via Paid Advertising",
          para3:
            "Stop wasting time and money on faulty and ineffective ad campaigns.",
          para4:
            "It's time to make your ad-budget count, scale your business and blow up your sales.",
          buttonTitle: "Speak TO OUR TEAM TODAY",
          buttonSubTitle: "Speak TO OUR TEAM TODAY",
        },
        section2: {
          title: "PAST SUCCESSES",
          para1:
            "Dont miss the chance to skyrocket your business like they HAVE",
          para2: "be apart of the circle, we’re taking over.",
        },
        section3: {
          title: "Solution we offer",
          tag1: {
            title: "2023 Marketing Automation Trends: The Future is Now",
            des: "Let’s take a closer look at Marketing Automation Trends that are expected to shape the future of marketing automation.",
            buttonText: "Learn more",
          },
          tag2: {
            title: "Streamlining Operations: Adapting to Efficiency in 2023",
            des: "Discover strategies for operational efficiency, tackling challenges, and seizing opportunities in the business landscape of 2023.",
            buttonText: "Learn more",
          },
          tag3: {
            title:
              "Reducing Employees: Navigating Workforce Optimization in 2023",
            des: "Explore workforce optimization strategies, addressing challenges and opportunities in the job market.",
            buttonText: "Learn more",
          },
        },
        section4: {
          subSec1: {
            title: "SCHEDULE AN APPOINTMENT",
          },
          subSec2: {
            title: "SCHEDULE YOUR CALL WITH DANY",
            demoCall: "Free 15-Minute Demo Call",
            para: `By the end of this Audit call, you will have a clear understanding of the next steps you can take for your business to start generating consistent and reliable results online with Funnels & Paid Advertising.`,
            para2: `Find a time on Dany’s calendar to schedule your call today and we look forward to speaking to you soon!`,
            formtitle: "DROP US A LINE",
            form: {
              first_name: "Full name",

              email: "Your mail",
              subject: "Subject",
              text: "Tell us about your Project",
              buttonText: "Submit",
            },
          },
        },
      },
    },
  },
  {
    lang: "Estonian",
    content: {
      appbar: {
        itemOne: "teenust",
        itemTwo: "Võta meiega ühendust",
        langTag: "et",
      },
      body: {
        section1: {
          para1: "MODERNE PÄEVAMAAGIA",
          para2: "Aitame e-kaubanduses",
          para3:
            "Lõpetage aja ja raha raiskamine vigasetele ja ebatõhusatele reklaamikampaaniatele.",
          para4:
            "On aeg muuta oma reklaamieelarve oluliseks, laiendada oma äritegevust ja suurendada müüki.",
          buttonTitle: "Rääkige MEIE MEESKONNAGA TÄNA",
          buttonSubTitle: "Rääkige MEIE MEESKONNAGA TÄNA",
        },
        section2: {
          title: "MINISTIKUD ÕNNUD",
          para1:
            "Ärge jätke kasutamata võimalust oma ettevõtet hüppeliselt tõsta, nagu neil ON",
          para2: "ole ringist osa, võtame üle.",
        },
        section3: {
          title: "Tulevik",
          tag1: {
            title: "Veebiarendus",
            des: "Tööstus säilitas meetodid, mis tagavad edu.",
            buttonText: "loe nüüd",
          },
          tag2: {
            title: "Kohale toimetatud",
            des: "Tööstus säilitas meetodid, mis tagavad edu.",
            buttonText: "loe nüüd",
          },
          tag3: {
            title: "Sujuvalt",
            des: "Tööstus säilitas meetodid, mis tagavad edu.",
            buttonText: "loe nüüd",
          },
        },
        section4: {
          subSec1: {
            title: "LEPPIDA AEG",
          },
          subSec2: {
            title: "PLANEERIGE OMA KÕNE DANYGA",
            demoCall: "Tasuta 15-minutiline demokõne",
            para: `Selle auditikõne lõpuks on teil selge arusaam järgmistest sammudest, mida saate oma ettevõtte jaoks teha, et lehtrite ja tasulise reklaami abil veebis järjepidevaid ja usaldusväärseid tulemusi luua.`,
            para2: `Leidke Dany kalendrist aeg, et oma kõne täna ajastada ja ootame teid varsti rääkima!`,
            formtitle: "VÕTKE MEIEGA ÜHENDUST e-posti kaudu",
            form: {
              first_name: "eesnimi",
              last_name: "perekonnanimi",
              email: "email",
              subject: "teema",
              text: "tekst",
              buttonText: "Esita",
            },
          },
        },
      },
    },
  },
];

export default LANGUAGE;
